<template>
  <div class="copy-right border-top">
    <ul class="footer1 d-md-flex justify-content-center my-2"
      v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes'">
      <li><router-link to="/pages">About us</router-link></li>
      <li><router-link to="/support">Support</router-link></li>
      <li><router-link to="/faq" href="">FAQ</router-link></li>

      <li v-for="(page, index) in $auth.setting.static_pages" :key="index" v-if="index !== 0">
        <div>
          <a target="_blank" :href="page.url ? page.url : '#'">{{ page.page_name }}</a>
          <span v-if="index !== $auth.setting.static_pages.length - 1"></span>
        </div>
      </li>

      <!-- <li v-for="(page, index) in $auth.setting.static_pages" :key="index">
        <a target="_blank" :href="page.url ? page.url : '#'">{{ page.page_name }}</a>
        <span v-if="index !== $auth.setting.static_pages.length - 1"></span>
      </li> -->

      <!-- <li><a target="_blank"
          :href="$auth.setting.static_pages && $auth.setting.static_pages[2] && $auth.setting.static_pages[2].url ? $auth.setting.static_pages[2].url : '#'">Terms
          & Conditions</a></li>
      <li><a target="_blank"
          :href="$auth.setting.static_pages && $auth.setting.static_pages[1] &  & $auth.setting.static_pages[1].url ? $auth.setting.static_pages[1].url : '#'">Privacy
          Policy</a> </li> -->
    </ul>
    <p v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes'">
      <span><a class="primary-link">Copyright </a>&#169; <a target="_blank" class="primary-link">{{ $auth.getAppName()
      }}</a> {{ getYear }}</span>
    </p>

    <!-- <cookie-law theme="dark-lime">
      <div slot="message">This {{$auth.getAppName()}} uses cookies to provide you with a great user experience. Please accept cookies for excellent optimal performance</div>
    </cookie-law> -->
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
export default {
  name: 'footerpage',
  components: { CookieLaw },
  computed: {
    getYear() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    }
  },
  mounted() {
    if (this.$auth.user) {
      // change condition for intercom
      // this.$auth.setting.is_intercom_configurations == 'Yes' &&
      if (this.$auth && this.$auth.setting && this.$auth.setting.intercom_details.is_enable == true ) {
        this.initializeIntercom()
      }
    }
  },
  methods: {
    initializeIntercom() {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "d5zbw9m3",
        name: this.$auth.user.user_name,
        user_id: this.$auth.user.user_id,
        email: this.$auth.user.email,
        created_at: this.$auth.user.created_at
      };
      window.v_id = this.$auth.setting.intercom_configuration_appid;
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + w.v_id;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },

  },

}
</script>